
import bellAnimationJson from '~/static/animations/bell.json'

export default {

    data(){
        return {
            bellAnimation: {
                animationData: bellAnimationJson,
                animationSpeed: 1,
                autoplay: true,
                renderer: 'svg',
                loop: true
            },
        }
    },
}
